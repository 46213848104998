@import '~@angular/material/prebuilt-themes/indigo-pink.css';


body {
  /*font-family: Roboto, Arial, sans-serif;*/
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
  margin: 0;
  padding: 30px;
}


.form-control:focus {

  border-color: inherit!important;
  outline: 0!important;
  box-shadow:none!important;
}

.version-info {
  font-size: 8pt;
  float: right;
  margin: 8px;
}

.pos-column {
  /*padding: 12px;*/
  max-height: 400px;
  overflow: auto;
}
.pos-column-description {
  border: 1px solid #eee;
  position: relative;
  min-height: 400px;
  text-align: left;
  font-size: 13px;
  overflow: auto;
}

.pos-column-description .title {
  font-size: 14px;
}

.pos-column-summery {
  border: 1px solid #eee;
  position: relative;
  min-height: 400px;
  overflow: auto;
}

.pos-column-summery .cartitem {
  font-size: 13px;
}

.pos-ul {
  list-style: none;
  text-align: center;
  padding-left: 0;
}

.pos-ul  li {
  border: 1px solid #eee;
  padding: 12px;
}

.pos-ul li:hover {
  background: #eee !important;
}

.pos-ul > .selected {
  background: silver !important;
}
.pos-ul li {
  overflow: auto;
}
.pos-ul li img {
  float: left;
}

.description-footer-btn {
 height: 36px;
  background-color:transparent;color:black;border-radius:0px;border-color:#a3a098;width: 20%;
  border-right: none;
}
.description-footer-btn-last {
  border-right: solid #a3a098 !important;
}

.cart, .total {
  text-align: left !important;
  font-size: 13px;
}

.cart span, .total span {
  float: right;
}
.no-padding {
  padding: 0 !important;
}


.cart {
  padding: 0px;
}

.subproduct {
  float: left !important;
  display: block;
  width: 85%;
  font-size: 12px;
  margin-left: 8%;
}

/* Importing Bootstrap SCSS file. */
/*@import '~bootstrap/scss/bootstrap';*/
@import "~@ng-select/ng-select/themes/default.theme.css";
.cancel-btn-width {
  width: 140px !important;
}
.text_siz{font-size: 16px!important; font-weight: bold!important; margin-top: 20px!important;}




.multistepsform {
  width:100%;
  margin: 10px auto;
  text-align: center;
  position: relative;
}
.multistepsform fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  position: relative;
}
.multistepsform fieldset:not(:first-of-type) {
  display: none;
}
.multistepsform input, .multistepsform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 13px;
}
.multistepsform input:focus, .multistepsform textarea:focus {
  border-color: #679b9b;
  outline: none;
  color: #637373;
}
.multistepsform .action-button {
  width: 100px;
  background: #f38022;
  font-weight: bold;
  color: #fff;
  transition: 150ms;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}
.multistepsform .action-button:hover, .multistepsform .action-button:focus {
  box-shadow: 0 0 0 2px #f08a5d, 0 0 0 3px #ff9 76;
  color: #fff;
}
.multistepsform .fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
}
.multistepsform .fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}
.multistepsform .multi-step-form {
  margin-bottom:0px;
  overflow: hidden;
  counter-reset: step;
  display: flex;
  align-items: center;
  width: 65%;
  margin: 0 auto;
}
.multistepsform .multi-step-form li {

  list-style-type: none;
  color: #000000;
  text-transform: capitalize;
  font-size: 13px;
  width: 33.33%;
  float: left;
  position: relative;
  font-weight: 500;

}
.multistepsform .multi-step-form li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: block;
  font-size: 0;
  color: #fff;
  background: #ffc000;
  border-radius: 100%;
  margin: 0 auto 5px auto;
  border: solid 1px #d9650e;
}
.multistepsform .multi-step-form li:after {
  content: "";
  width: 100%;
  height: 2px;
  background:#f38022;
  position: absolute;
  left: -50%;
  top:15px;
  z-index: -1;
}
.multistepsform .multi-step-form li:first-child:after {
  content: none;
}
.multistepsform .multi-step-form li.active {
  color: #f38022;
}
.multistepsform .multi-step-form li.active:before, .multistepsform .multi-step-form li.active:after {
  background: #f38022;
  color: white;
}


.needs-validation label{font-size: 14px;font-weight: 500;  text-transform: capitalize;} 
.headebar-profile{background: #f38022;
  color: #fff;
  padding: 10px;
  font-weight: 500;
  text-transform: capitalize;margin-bottom: 20px;}
.span-imp{color: red;}
.edit-address:hover{text-decoration: none;}




@media (max-width:767px) { 

body{padding: 0!important;}
.cardFlex{flex-wrap: wrap;}
.multistepsform .multi-step-form{margin: 0;padding: 0;width: 100%;    align-items: baseline;top: 10px;}
.Temp{width: 100%!important;}
.btn-checkout{margin-left: 2px;}
.table-responsive .table tbody tr td, .table-responsive .table tfoot tr td{font-size: 12px;}
.multistepsform .multi-step-form li:before{width: 20px;height: 20px;}
.multistepsform .multi-step-form li:after{top: 10px;}
.multistepsform .multi-step-form li{font-size: 11px;}
.select-kit{margin: 10px 0px!important;flex-wrap: wrap;justify-content: center;
.form-control{width: 100%!important;margin:5px 0 0px!important;}}
.pos-column{overflow: inherit;    min-height: auto;}
.ng-option{text-align: left;}
.ptcontainer {margin-bottom:10px;}
.pack-heading{font-size:15px;margin: 0;display: none;}
.mini-pack-heading{margin-bottom:5px;font-size: 13px;display: none;}
.heading-smart-home h2{font-size: 22px;}
.fixed-fter { position: fixed;bottom: -16px;width: 100%;left: 0; padding: 6px 10px;background: #fff;}
.heading-smart-home h2 {font-size: 20px!important;background: #ddd;padding: 10px 0px;}
.pos-column-summery{min-height:100px;}
.pos-column-description{margin-bottom:0}
.btn-fixed-mob{
  position: fixed;
    bottom:5px;
    left: 0;
    width:98%;
    z-index: 999;
    margin: 0 auto;
    right: 0;
}

}


@media (max-width: 768px) {  }


@media (max-width: 992px) {  }

@media (max-width: 1200px) {  }


@media (max-width: 1400px) {  }